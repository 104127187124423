import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import ProductDataService from "../services/product.service";
import Table from "react-bootstrap/Table";
//import Form from 'react-bootstrap/Form';
//import { MDBCheckbox } from 'mdb-react-ui-kit';


export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveProducts = this.retrieveProducts.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveProduct = this.setActiveProduct.bind(this);
    this.searchTitle = this.searchTitle.bind(this);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" }
    };

    this.state = {
      products: [],
      currentProduct: null,
      currentIndex: -1,
      searchTitle: ""
    };
  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser)
      this.setState({ redirect: "/home" });
    //Logovan korisnik
    this.setState({ currentUser: currentUser, userReady: true });
    //Products
    this.retrieveProducts();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;
    this.setState({
      searchTitle: searchTitle
    });
  }

  retrieveProducts() {
    ProductDataService.getAll()
      .then(response => {
        this.setState({
          products: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveProducts();
    this.setState({
      currentProduct: null,
      currentIndex: -1
    });
  }

  setActiveProduct(product, index) {
    this.setState({
      currentProduct: product,
      currentIndex: index
    });
  }


  searchTitle() {
    ProductDataService.findByTitle(this.state.searchTitle)
      .then(response => {
        this.setState({
          products: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const { products } = this.state;

    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <>
        <div className="container">
          <div className="jumbotron">
            <strong>Podatci za pristup serveru preko  RestAPI konekcije. Koristite vaše login podatke i navedenu putanju.</strong>
            <hr></hr>
            <strong>Username : Vaše korisničko ime  </strong><br></br>
            <strong>Password : Vaš Password</strong><br></br>
            <hr></hr>
            <strong >https://api.gama-electronic.com/api/products</strong><hr></hr>
            <strong>Pripremio sam i module za export podataka al trenutno mi je najbitnije da radi RestAPI tj. živi podatci iz baze podataka API aplikacije koja ima procedure za sync.</strong><br></br>
            <strong>JSON podatke trenutno možete preuzeti bez autentikacije "test".</strong><br></br>
            <strong>NPR :<br></br>
              "id":697,"title":"8X8","description":"Hladnjak Gigatech 8cm crni","acClassif":"Gigatech","acClassif2":"Hladnjaci",
              "acWarehouse":"VP ŽEPČE","anStock":285,"anSalePrice":3.51,"anRTPrice":3.15,"anRebate":35,"Vasa_cijena":2.05,"acDescr":null,
              "acPicture":"https://www.b2b.gama-electronic.com/images/products/3770_3d3851af524fe52ddfdea79a1be09880suS.png",
              "published":"1","published_acClassif":"1","published_acATWarehouse":"1",
              "createdAt":"2023-10-18T04:59:42.147Z","updatedAt":"2023-10-18T04:59:42.147Z"</strong>
            <hr></hr>
            <strong>U TESTNOM MODU NISU POTREBNI LIGIN PODATCI </strong>
            <hr></hr>
            <strong>FrontEnd je pripremljen gdje je login neophodan i gdje će bit omogućen download u XML,CSV,XLSX itd</strong><br></br>
            <hr></hr>
            <strong>Link: https://admin.api.gama-electronic.com/login </strong>
           
          </div>


          <div className="container">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Slika</th>
                  <th>Šifra</th>
                  <th>Naziv</th>
                  <th>K1</th>
                  <th>K2</th>
                  <th>Zaliha</th>
                  <th>VP</th>
                  <th>Popust</th>
                  <th>Cijena</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-wrap" style={{ height: "100px" }}>
                          <img src={product.acPicture} alt="" style={{ height: "90px" }}></img>

                        </div>
                      ))}
                  </td>
                  <td>
                    {products.map((product, index) => (
                      <div key={index} className="text-nowrap small" style={{ height: "100px" }}>
                        {product.title}

                      </div>
                    ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-wrap small" style={{ height: "100px", width: "300px" }}>
                          {product.description}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.acClassif}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.acClassif2}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.anStock}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.anRTPrice}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.anRebate}%
                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.Vasa_cijena}
                        </div>
                      ))}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }
}
