import React, { Component } from "react";

import UserService from "../services/user.service";


export default class BoardModerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getModeratorBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

      }
    );
  }

  render() {
    return (
      <div class="jumbotron">
        <div class="container">
          <h1 class="display-3">Moderatorska stranica!</h1>
          <p>Sadžaj moderatorske stranice mogu vidjet samo korisnici sa moderatorskim pravima.</p>
        </div>
      </div>
    );
  }
}


/*
  render() {
    return (
      <div>
        <link href="https://unpkg.com/bootstrap-table@1.22.1/dist/bootstrap-table.min.css" rel="stylesheet" />

        <script src="https://cdn.jsdelivr.net/npm/tableexport.jquery.plugin@1.10.21/tableExport.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/tableexport.jquery.plugin@1.10.21/libs/jsPDF/jspdf.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/tableexport.jquery.plugin@1.10.21/libs/jsPDF-AutoTable/jspdf.plugin.autotable.js"></script>
        <script src="https://unpkg.com/bootstrap-table@1.22.1/dist/bootstrap-table.min.js"></script>
        <script src="https://unpkg.com/bootstrap-table@1.22.1/dist/extensions/export/bootstrap-table-export.min.js"></script>

        
        <table id="table"
          data-show-export="true"
          data-pagination="true"
          data-side-pagination="server"
          data-click-to-select="true"
          data-toolbar="#toolbar"
          data-show-toggle="true"
          data-show-columns="true"
          data-url="https://examples.wenzhixin.net.cn/examples/bootstrap_table/data">
        </table>


      </div>

    );
  }
}
*/