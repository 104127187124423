import React, { Component } from "react";
import UserService from "../services/user.service";
import ERPlogo from '../images/ERP/pantheon_ERP_logo_black.png'; // Logo ERP sistema ;
//import Table from 'react-bootstrap/Table';


export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

      }
    );
  }


  render() {
    return (
      <>
        <div class="jumbotron">
          <div class="container">
            <h1 class="display-3">Početna stranica Pantheon API aplikacije</h1>
            <hr></hr>
            <div>
              <a href="/" className="nav-item">
                <img src={ERPlogo} class="logo" alt="logo" height="40"></img>
                <div class="badge badge-secondary">
                  API
                </div>
                <div>
                  developed for Gama Electronic d.o.o.
                </div>
              </a>
            </div>
          </div>
          
        </div>
      
      </>

    );
  }


}