import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
//Logo import
import logo from './images/logo.jpg'; // Logo aplikacije
import ERPlogo from './images/ERP/pantheon_ERP_logo_black.png'; // Logo ERP sistema 
//Autentikacija
import AuthService from "./services/auth.service";
//Osnovne komponenete Hompage,Login.....
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
//Tutoriali 
//import AddTutorial from "./components/tutorials/add-tutorial.component";
//import Tutorial from "./components/tutorials/tutorial.component";
//import TutorialsList from "./components/tutorials/tutorials-list.component";
//Artikli
//import AddProduct from "./components/products/add-product.component";
//import Product from "./components/products/product.component";
//import ProductsList from "./components/products/products-list.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand-sm bg-light navbar-light">
          <a href="/" className="nav-item">
            <img src={ERPlogo} class="logo" alt="logo" height="40"></img>
          </a>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"}
                className="navbar-brand">
                <div class="badge badge-secondary">
                  API
                </div>
              </Link>
            </li>

            {showAdminBoard && (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/admin"}
                    className="btn btn-light btn-sm">
                    Administrator
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/mod"}
                    className="btn btn-light btn-sm">
                    Moderator
                  </Link>
                </li>
              </div>
            )}

            {showModeratorBoard && (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/mod"}
                    className="btn btn-light btn-sm">
                    Moderator
                  </Link>
                </li>
              </div>
            )}


            {currentUser && (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/User"}
                    className="btn btn-light btn-sm">
                    Preuzimanje podataka
                  </Link>
                </li>
              </div>
            )}
          </div>

          {currentUser ? (
            <>

              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/profile"}
                    className="btn btn-light btn-sm">
                    <strong>Korisnik</strong>
                    {" : " + currentUser.username}
                  </Link>
                </li>
              </div>

              <div>
                <li className="nav-item">
                  <a href="/login"
                    className="btn btn-danger active btn-sm"
                    onClick={this.logOut}>
                    Odjava
                  </a>
                </li>
              </div>
              </>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"}
                  className="btn btn-light btn-sm" >
                  Prijava
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/register"}
                  className="btn btn-light btn-sm">
                  Registracija
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />
          </Routes>
        </div>

        <div>
          <footer class="page-footer font-small blue">
            <div class="footer-copyright text-center py-3">
              <a href="/" >
                Pantheon API
                © 2023 Copyright: Ermin Taletović
                <img src={logo} class="logo" alt="logo" height="40"></img>
              </a>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default App;
