import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import ProductDataService from "../services/product.service";
//import Table from 'react-bootstrap/Table';
import ERPlogo from '../images/ERP/pantheon_ERP_logo_black.png'; // Logo ERP sistema 



export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveProducts = this.retrieveProducts.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveProduct = this.setActiveProduct.bind(this);
    this.searchTitle = this.searchTitle.bind(this);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" }
    };

    this.state = {
      products: [],
      currentProduct: null,
      currentIndex: -1,
      searchTitle: ""
    };
  }
  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser)
      this.setState({ redirect: "/home" });
    //Logovan korisnik
    this.setState({ currentUser: currentUser, userReady: true });
    //Products
    this.retrieveProducts();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;
    this.setState({
      searchTitle: searchTitle
    });
  }

  retrieveProducts() {
    ProductDataService.getAll()
      .then(response => {
        this.setState({
          products: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveProducts();
    this.setState({
      currentProduct: null,
      currentIndex: -1
    });
  }

  setActiveProduct(product, index) {
    this.setState({
      currentProduct: product,
      currentIndex: index
    });
  }


  searchTitle() {
    ProductDataService.findByTitle(this.state.searchTitle)
      .then(response => {
        this.setState({
          products: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { currentUser, searchTitle, products } = this.state;
    return (
      <>
        <div className="container">
          <div class="row">
            <div class="col-6 card-header">
              {(this.state.userReady) ?
                <div>
                  <header>
                    <h3>
                      <strong>{currentUser.username}</strong>
                    </h3>
                  </header>
                  <p>
                    <strong>Username:</strong>{" "}
                    {currentUser.username}
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    {currentUser.email}
                  </p>
                  <p>
                    <strong>Token:</strong>{" "}
                    {currentUser.accessToken.substring(0, 20)} ...{" "}
                    {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
                  </p>
                  <p>
                    <strong>Ovlaštenja/role level:</strong>
                    {currentUser.roles &&
                      currentUser.roles.map((role, index) => <div key={index}>{role}</div>)}
                  </p>
                </div> : null}
            </div>
            <div class="col-6 card-header">
              <img src={ERPlogo} class="logo" alt="logo" height="40"></img>
              <div class="badge badge-secondary">
                API
              </div>
              <hr></hr>
              {(this.state.userReady) ?
                <div>
                  <header>
                    <h6>
                      <strong>{currentUser.username}</strong>
                    </h6>
                  </header>
                </div> : null}
            </div>
          </div>
          <br></br>
          <div className="list row">
            <div className="col-md-12">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pretraga po šifri"
                  value={searchTitle}
                  onChange={this.onChangeSearchTitle} />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={this.searchTitle}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        
        
          <table>
              <thead>
                <tr>
                  <th>Slika</th>
                  <th>Šifra</th>
                  <th>Naziv</th>
                  <th>K1</th>
                  <th>K2</th>
                  <th>Zaliha</th>
                  <th>VP</th>
                  <th>Popust</th>
                  <th>Cijena</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-wrap" style={{ height: "100px" }}>
                          <img src={product.acPicture} alt="" style={{ height: "90px" }}></img>

                        </div>
                      ))}
                  </td>
                  <td>
                    {products.map((product, index) => (
                      <div key={index} className="text-nowrap small" style={{ height: "100px" }}>
                        {product.title}

                      </div>
                    ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-wrap small" style={{ height: "100px", width: "300px" }}>
                          {product.description}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.acClassif}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.acClassif2}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.anStock}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.anRTPrice}

                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.anRebate}%
                        </div>
                      ))}
                  </td>
                  <td>
                    {products &&
                      products.map((product, index) => (
                        <div key={index} className="text-nowrap  small" style={{ height: "100px" }}>
                          {product.Vasa_cijena}
                        </div>
                      ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </>
    )
  }
}

/*
        <Table striped bordered>
          <thead>
            <tr>
              <th>Slika</th>
              <th>Šifra</th>
              <th>Naziv</th>
              <th>Cat1</th>
              <th>Cat2</th>
              <th>Zaliha</th>
              <th>VPC</th>
              <th>R%</th>
              <th>Cijena</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td >
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap" style={{ height: "60px" }}>
                    <img src={product.img} alt="" height="50px"></img>
                  </FormLabel>
                ))}
            </td>
            <td>
              {products.map((product, index) => (
                <FormLabel key={index} className="text-wrap small" style={{ height: "60px" }}>
                  {product.title}
                </FormLabel>
              ))}
            </td>

            <td >
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap small" style={{ height: "60px" }}>
                    {product.description}
                  </FormLabel>
                ))}
            </td>
            <td>
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap  small" style={{ height: "60px" }}>
                    {product.acClassif}
                  </FormLabel>
                ))}
            </td>
            <td>
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap  small" style={{ height: "60px" }}>
                    {product.acClassif2}
                  </FormLabel>
                ))}
            </td>
            <td>
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap  small" style={{ height: "60px" }}>
                    {product.anStock}
                  </FormLabel>
                ))}
            </td>
            <td>
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap  small" style={{ height: "60px" }}>
                    {product.anRTPrice}
                  </FormLabel>
                ))}
            </td>
            <td>
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap  small" style={{ height: "60px" }}>
                    {3} 
                  </FormLabel>
                ))}
            </td>
            <td>
              {products &&
                products.map((product, index) => (
                  <FormLabel key={index} className="text-wrap  small" style={{ height: "60px" }}>
                    {product.anRTPrice - 3} 
                  </FormLabel>
                ))}
            </td>
            </tr>
          </tbody>
        </Table>

*/
